import Heading from "@components/font/heading";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React, {useRef} from "react";
import { Offcanvas } from "react-bootstrap";
import BookieRecordSection from "./profileBookie/bookieRecord";
import { Bookie } from "@api/useUpdateBookiesMultiple";
import styled from "styled-components";
import { useFilter } from "@context/FilterContext";
import FilterSection from "@components/Filter/FilterSection";
import { useForm } from "react-hook-form";
import { Button } from "@components/button/button";
import { FilterType, useStatsChart } from "@context/StatsChartContext";
import { useTranslation } from "react-i18next";
import { useCalendar } from "@context/CalendarContext";

const ScrollableBody = styled.div`
  height: 100%;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: ${theme.colors.grey.darker};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.grey.dark};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${theme.colors.grey.normal};
  }
`;

const DrawerRecord = ({
  setShow,
  show,
  bookie,
  filterType,
}: {
  setShow: (value: boolean) => void;
  show: boolean;
  bookie: Bookie;
  filterType: FilterType;
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setShow(false);
    setFilterOpen(false);
  };

  const { clearFilters, applyFilters } = useStatsChart();
  const { setFilterOpen, filterOpen } = useFilter();
  const { resetData } = useCalendar();
  

  const { control } = useForm({
    defaultValues: {
      search: "",
    },
  });

  const handleApplyFilters = () => {
    applyFilters(filterType);
    setFilterOpen(false);
    setShow(true);
  };

  const handleCleanFilters = () => {
    clearFilters(filterType);
    resetData();
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={{
          background: theme.colors.grey.darker,
          borderLeft: `0.5px solid ${theme.colors.white.dark}`,
        }}
      >
        <Offcanvas.Header
          className="pt-2 pb-2"
          style={{
            borderBottom: `0.5px solid ${theme.colors.white.dark}`,
          }}
        >
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{ padding: "6px 8px" }}
          >
            <div>
              <Heading
                fontSize="20px"
                title={bookie.bookie}
                fontWeight="700"
                color={theme.colors.white.normal}
              />
            </div>
            <div style={{display:'flex', alignItems:'center', gap:'16px', cursor:'pointer'}}>
              {/* <Icon
                onClick={()=> {
                  setFilterOpen(!filterOpen);
                }}
                style={{ color: theme.colors.white.normal }}
                icon="charm:filter"
                width="24"
                height="24"
              /> */}
              <Icon
                onClick={handleClose}
                style={{ color: theme.colors.white.normal }}
                icon="bitcoin-icons:cross-filled"
                width="28"
                height="28"
              />
            </div>
          </div>
        </Offcanvas.Header>
        <ScrollableBody>
          <Offcanvas.Body style={{padding:'0px'}}>
            {
              filterOpen ? (
                <FilterSection control={control} filterType="bookies" />
              ):(
                <BookieRecordSection bookie={bookie} drawer={true} />
              )
            }
          </Offcanvas.Body>
        </ScrollableBody>
        {
          filterOpen && (
            <div
            style={{ borderTop: `0.5px solid ${theme.colors.white.dark}` }}
          >
            <div
              className="d-flex justify-content-between align-items-center w-100"
              style={{ padding: "16px 24px", gap: 16 }}
            >
              <Button
                title={t("profile.cleanFilters")}
                outline
                onClick={handleCleanFilters}
                style={{
                  width: "100%",
                  fontWeight: "700",
                  fontSize: "14px",
                  borderRadius: "8px",
                }}
              />
              <Button
                title={t("profile.applyFilters")}
                onClick={handleApplyFilters}
                style={{
                  width: "100%",
                  fontWeight: "700",
                  fontSize: "14px",
                  borderRadius: "8px",
                }}
              />
            </div>
          </div>
          )
        }
      </Offcanvas>
    </>
  );
};

export default DrawerRecord;
