import React, { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import Heading from "@components/font/heading";
import { RadioButton } from "@components/form/radioButton";
import { TabContext } from "@context/TabContext";
import PaymentForm from "@pages/PaymentForm";
import { theme } from "@styles/themes";
import { usePayment } from "@context/PaymentContext";
import { paymentMethods, PaymentMethodType } from "@utils/payments";
import { PaymentFooter } from "./components/paymentFooter";
import { useTranslation } from "react-i18next";

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDivBox = styled.div`
  border: 1px solid ${theme.colors.white.dark};
  border-radius: 8px;
  cursor: pointer;
  padding: 16px;
`;

const ResponsiveDiv = styled.div`
max-width:730px;

  @media (min-width: 1200px) {
padding-right:36px
  }
  @media (max-width: 1199px) {
  padding-right:0
  width:100%;
  max-width:1300px;
  }
`;

const ContentBox = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

const StepTwo = () => {
  const { t } = useTranslation();
  // variables
  const tabContext = useContext(TabContext);
  const { setActiveTab } = tabContext;
  const { setPaymentMethod, currency } = usePayment();

  const { control, setValue, handleSubmit, watch } = useForm<{
    paymentMethod: PaymentMethodType;
  }>({
    defaultValues: {
      paymentMethod: paymentMethods.creditCard,
    },
  });

  const watchPaymentMethod = watch("paymentMethod");

  // effects
  useEffect(() => {
    setPaymentMethod(watchPaymentMethod);
  }, [watchPaymentMethod]);

  // functions
  const onSubmit = async (values: any) => {
    setActiveTab(2);
  };

  // returns
  return (
    <ResponsiveDiv>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col lg={12} md={12} sm={12} xs={12}>
            <StyledDivBox
              onClick={() =>
                setValue("paymentMethod", paymentMethods.creditCard)
              }
            >
              <div className="d-flex align-items-center">
                <RadioWrapper>
                  <RadioButton
                    name="paymentMethod"
                    value={paymentMethods.creditCard}
                    checked={
                      watch("paymentMethod") === paymentMethods.creditCard
                    }
                    {...control.register("paymentMethod", {
                      required: "Select a payment method",
                    })}
                  />
                </RadioWrapper>
                <div className="mx-3">
                  <Heading
                    fontSize="14px"
                    title={t("payments.creditCard")}
                    fontWeight="700"
                    color={theme.colors.white.normal}
                  />
                </div>
              </div>

              <ContentBox
                isVisible={watch("paymentMethod") === paymentMethods.creditCard}
              >
                <PaymentForm onPressContinue={onSubmit} currency={currency} />
              </ContentBox>
            </StyledDivBox>
          </Col>

          <Col lg={12} md={12} sm={12} xs={12}>
            <StyledDivBox
              onClick={() =>
                setValue("paymentMethod", paymentMethods.bankTransfer)
              }
            >
              <div className="d-flex align-items-center">
                <RadioWrapper>
                  <RadioButton
                    name="paymentMethod"
                    value={paymentMethods.bankTransfer}
                    checked={
                      watch("paymentMethod") === paymentMethods.bankTransfer
                    }
                    {...control.register("paymentMethod", {
                      required: "Select a payment method",
                    })}
                  />
                </RadioWrapper>

                <div className="mx-3">
                  <Heading
                    fontSize="14px"
                    title={t("payments.bankTransfer")}
                    fontWeight="700"
                    color={theme.colors.white.normal}
                  />
                </div>
              </div>
            </StyledDivBox>
          </Col>

            <Col lg={12} md={12} sm={12} xs={12}>
            <StyledDivBox style={{ opacity: 0.5, cursor: "not-allowed", display:"flex", alignItems:"center"}}>
              <div className="d-flex">
              <RadioWrapper>
                <RadioButton
                name="paymentMethod"
                value={paymentMethods.crypto}
                checked={false}
                disabled={true}
                {...control.register("paymentMethod")}
                />
              </RadioWrapper>

              <div className="mx-3 d-flex align-items-center w-100">
                <Heading
                fontSize="14px"
                title={t("payments.cryptoPayment")}
                fontWeight="700"
                color={theme.colors.white.normal}
                />
                <span
                style={{ 
                  color: theme.colors.white.normal, 
                  fontSize: "12px",
                  marginLeft: "8px"
                }}
                >
                {t("payments.comingSoon")}
                </span>
              </div>
              </div>
            </StyledDivBox>
            </Col>
        </Row>

        <PaymentFooter />
      </form>
    </ResponsiveDiv>
  );
};

export default StepTwo;
