import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import { regexPatterns } from "@utils/regex";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const StepFour = ({ control, setValue, getValues, trigger, errors }: any) => {
  const { t } = useTranslation();

  return (
    <div>
      <Row className="g-2">
        <Col md={12} sm={12} xs={12}>
          <TextField
            floatingLabel
            control={control}
            name="telegramUser"
            defaultValue=""
            bgColor={theme.colors.grey.dark}
            maxLength={25}
            size="sm"
            placeholder="@Telegram"
            onBlur={() => {
              if (getValues) {
                const value = getValues("telegramUser") || "";
                if (value && !value.startsWith("@")) {
                  setValue("telegramUser", `@${value}`);
                }
              }
              trigger("telegramUser");
            }}
            onChange={(e) => {
              const value = e.target.value || "";
              if (!value.startsWith("@") && value.length > 0) {
                setValue("telegramUser", `@${value}`);
              } else {
                setValue("telegramUser", value);
              }
            }}
            errors={errors?.telegramUser}
            rules={{
              required: false,
              pattern: {
                value: regexPatterns?.regTelegram,
                message: t("errors.telegramPattern"),
              },
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StepFour;
