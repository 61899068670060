import React, { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import BookieCard from "./bookieCard";
import { useGetBookiesRecord } from "@api/useGetBookiesRecord";
import { Bookie } from "@api/useUpdateBookiesMultiple";
import { Loading } from "@components/loader/Loading";
import { useTranslation } from "react-i18next";
import { theme } from "@styles/themes";
import { useFilter } from "@context/FilterContext";
import ScrollToTop from './Anchor';

const BookieRecordSection: React.FC<{ bookie: Bookie, drawer: boolean }> = ({ bookie, drawer }) => {
  const internalRef = useRef<HTMLDivElement>(null);

  const { ref, inView } = useInView({
    root: internalRef.current,
    rootMargin: "0px",
    threshold: 0.1,
  });
  
  const { t } = useTranslation();

  const { filterOpen } = useFilter();
  
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useGetBookiesRecord({
      bookieName: bookie.bookie,
      accountId: bookie.accountId,
      page: 1,
    });

  useEffect(() => {
    if (inView && !isFetchingNextPage && hasNextPage && data?.pages[0]?.length > 0) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage, isFetchingNextPage, data]);
  
  if (!data || isLoading) return <Loading />;
  
  if (data.pages[0]?.length === 0) {
    return <div style={{ padding: "10px", color:theme.colors.white.normal}}>{t("profile.nohistoricaldata")}</div>;
  }

  return (
    <div style={{overflowY: "auto", height:'100vh' }} ref={internalRef}>
      {
        filterOpen ? (
            <>add filter in mobile</>
        ) : (
          <>
          <ScrollToTop containerRef={internalRef} />
          {
            data.pages.map((page, pageIndex) => (
            <div key={pageIndex} style={{ padding: '0px 16px' }}>
                          {page.map((elem, index) => (
                 <BookieCard key={`${pageIndex}-${index}`} {...elem} />
             ))}
            </div>
            ))
          }
          </>
        )
      }
      <div ref={ref} style={{ height: "20px", marginBottom: drawer ? "90px": "30px" }}>
        {isFetchingNextPage ? <Loading /> : ""}
      </div>
    </div>
  );
};

export default BookieRecordSection;
