import React, { useState, useEffect } from "react";
import Heading from "@components/font/heading";
import { usePayment } from "@context/PaymentContext";
import { theme } from "@styles/themes";
import { paymentMethods, paymentMethodText } from "@utils/payments";
import { useTranslation } from "react-i18next";
import { PaymentFooter } from "./components/paymentFooter";
import AcceptTaCPayment from './components/AcceptTaCPayment';
import styled from "styled-components";
import divider from "@assets/payments/divider.svg";
import { terms } from '@utils/termsAndConditions';
import { useForm } from "react-hook-form";
import Modal from '@components/modal/Modal';

const DividerContainer = styled.div`
  margin: 24px 0;
  width: 100%;
  overflow: hidden;
`;

const StepThree = ({ handleClick }) => {
  const { t } = useTranslation();
  const { paymentMethod, currency, credit, billingDetails, setIsAcceptedTerms } = usePayment();

  const [showModal, setShowModal] = useState(false);

  const {
    watch,
    control,
    setValue
  } = useForm({
    defaultValues: {
      isAcceptedTerms: false,
    },
  });

  const watchIsAcceptedTerms = watch("isAcceptedTerms");

  useEffect(() => {
    setIsAcceptedTerms(watchIsAcceptedTerms);
},[watchIsAcceptedTerms]);

const handleClose = () => setShowModal(false);

  return (
    <div>
      <div
        className="my-4 d-block d-md-none"
        style={{
          backgroundColor: theme.colors.grey.dark,
          padding: "16px",
          borderRadius: "8px",
        }}
      >
        <Heading
          title={`${credit} ${t("payments.credits")}`}
          fontSize="24px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />
      </div>

      <div>
        <Heading
          title={(t("profile.currency"))}
          fontSize="17px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />

        <Heading
          className="mt-2"
          title={currency.toUpperCase()}
          fontSize="17px"
          fontWeight="400"
          color={theme.colors.white.dark}
        />
      </div>

      <div className="mt-4">
        <Heading
          title={(t("profile.billingDetails"))}
          fontSize="17px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />

        <Heading
          className="mt-2"
          style={{ width: "60%" }}
          title={`${billingDetails?.name || billingDetails?.companyName } ${billingDetails?.surname ?? ''} 
            ${billingDetails?.address}, ${billingDetails?.addressExtra},
          ${billingDetails?.city} ${billingDetails?.taxId || billingDetails?.personId} (${billingDetails?.country})
          `}
          fontSize="17px"
          fontWeight="400"
          color={theme.colors.white.dark}
        />
      </div>

      <div className="mt-4">
        <Heading
          title={(t("profile.paymentMethod"))}
          fontSize="17px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />

        <Heading
          className="mt-2"
          style={{ width: "60%" }}
          title={paymentMethodText[paymentMethod]}
          fontSize="17px"
          fontWeight="400"
          color={theme.colors.white.dark}
        />
        <DividerContainer>
          <img src={divider} alt="divider line" 
              style={{
              width: "auto",
          }} />
        </DividerContainer>
        <AcceptTaCPayment 
        setShowModal={setShowModal} 
        control={control} 
        watch={watch} 
        watchIsAcceptedTerms={watchIsAcceptedTerms} 
        />
      </div>

      <PaymentFooter
        buttonTitle={
          paymentMethod === paymentMethods.bankTransfer
            ? t("payments.seeBankDetails")
            : t("payments.pay")
        }
        onClick={handleClick}
      />
      <Modal
       show={showModal}
       handleClose={handleClose}
       closeBtnTitle={t("payments.cancel")}
       actionBtnTitle={t("payments.agreeTaC")}
       title={t("payments.termsAndConditionsTitle")}
       body={terms}
       onActionClick={()=> {
        setIsAcceptedTerms(true);
        setValue("isAcceptedTerms", true);
        setShowModal(false);
      }}
        />
    </div>
  );
};

export default StepThree;
