// Generated by https://quicktype.io

export type RequestFields = { [key: string]: string | number };
export interface ServerResponse<T> {
  data: T;
  success: boolean;
}
export interface NotficationResponse<T> {
  data?: any;
  success?: boolean;
}
export type AuthResponse = {
  token: string;
  myAccount?: MyAccount;
  settings?: Settings;
};

export interface MyAccount {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  phonePrefix?: string;
  telegramUser?: string;
}

export interface Settings {
  currency?: string;
  dateFormat?: string;
  language?: string;
  numberFormat?: string;
  timeZone?: string;
}

export interface User {
  _id: ID;
  user: string;
  password: string;
  bookie: string;
  country: string;
  stake: string;
  customId: string;
  error?: boolean;
  scheduler?: SchedulerType[] | string;
  comments: string;
  service: string;
  isRunning: boolean;
  total_bank?: number;
  initial_bank?: number;
  currentOB_number?: number;
  last_bank?: number;
  finalBank?: number;
  closing_reason?: number;
  lastOP_update?: { $date: number };
  slot?: boolean;
}

export interface ID {
  $oid: string;
}

export interface SchedulerType {
  turnOnTime?: number;
  turnOffTime?: number;
}

export interface Address {
  address: string;
  addressExtra: string;
  billingId: string;
  city: string;
  country: string;
  isCompany: boolean;
  isDefault: boolean;
  name: string;
  personID: string;
  phoneNumber?: string;
  phonePrefix?: string;
  surname: string;
  taxId?: string; 
  zipCode: string;
  companyName?: string;
}

export interface PaymentSummaryData {
  credits: number;
  creditsFee: {
    EUR: number;
    USD: number;
  };
  defaultCurrency: string;
  paymentsRecord: PaymentsRecord[];
}

export interface PaymentsRecord {
  method: number;
  paymentAmount: number;
  status: number;
  uploadTimestamp: number;
  invoiceCreated?: boolean;
  invoicePath?: string;
  invoiceUrl?: null;
}

export interface BookieRecord {
  amount: number;
  away: string;
  betType: string;
  eventDate: number;
  placedDate: number;
  home: string;
  market: string;
  odd: number;
  payout: number;
  result: Result;
  sport: string;
}

export enum Result {
  Loss = "loss",
  Pending = "pending",
  Win = "win",
  Void = "void",
}

export type CreateBookiesParams = {
  username: string;
  password: string;
  bookie: string;
  birthday: number; // Ensure this is a string
  closingDate: number; // Assuming this remains a timestamp
  profileId: string;
  isEnabled: boolean;
  isRunning: boolean;
  country: string;
  creditLimit?: any; // Adjust type as necessary
  stake?: any; // Adjust type as necessary
  scheduler: {
    turnOnTime?: number;
    turnOffTime?: number;
  }[];
};
export interface UpdateBookiesParams {
  bookieId: string;
  username?: string;
  password?: string;
  bookie: string;
  birthday?: number;
  closingDate?: number;
  profileId?: string;
  isEnabled?: boolean;
  isRunning?: boolean;
  country?: string;
  creditLimit?: any;
  stake?: any;
  scheduler?: {
    turnOnTime?: number;
    turnOffTime?: number;
  }[];
}
export interface AddProfileParams {
  profileName: string;
  country: string;
}

export interface MyAccountData {
  newPassword?: string;
  currentPassword?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  phonePrefix?: string;
  telegramUser?: string;
}

export interface SportStats {
  amount: number;
  avgOdd: number;
  lostBets: number;
  numBets: number;
  profits: number;
  voidBets: number;
  winBets: number;
  avgStake: number;
}

export interface ProfileStats {
  profileId: string;
  profileName: string;
  sports: {
    [key: string]: SportStats;
  };
}

export interface StatsData {
  currency: string;
  stats: {
    [bookmaker: string]: ProfileStats[];
  };
}
