import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import Heading from "@components/font/heading";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import { SearchSelectBox } from "@components/form/searchSelect";
import { Button } from "@components/button/button";
import { TabContext } from "@context/TabContext";
import { City, ICountry } from "country-state-city";
import { RadioButton } from "@components/form/radioButton";
import { useGetAddresses } from "@api/useGetAddress";
import { AddAddressRequest } from "@api/useAddAddress";
import { Address } from "@api/types";
import { AddressItem } from "@components/AddressItem";
import { appCountries } from "@utils/countries";
import { usePayment } from "@context/PaymentContext";
import { CheckboxField } from "@components/CheckboxField";
import { PaymentFooter } from "./components/paymentFooter";
import { regexPatterns } from "@utils/regex";
import { useForm as useFormContext } from "@context/FormContext";
import { useAuth } from "@context/AuthContext";

interface CollapsibleSectionProps {
  isCollapsed: boolean;
  maxHeight: string;
}

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CollapsibleSection = styled.div<CollapsibleSectionProps>`
  overflow: hidden;
  max-height: ${(props) => props.maxHeight};
  transition: max-height 0.4s ease, opacity 0.4s ease;
  opacity: ${(props) => (props.isCollapsed ? 0 : 1)};
`;

const ResponsiveDiv = styled.div`
  max-width: 730px;

  @media (min-width: 1200px) {
    padding-right: 36px;
  }
  @media (max-width: 1199px) {
    margin-bottom: 90px !important;
    padding-right: 0;
    width: 100%;
    max-width: 1300px;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: underline;
`;

const RotatingIcon = styled(Icon)<any>`
  color: ${theme.colors.white.normal};
  transition: transform 0.3s ease; // Add transition for rotation
  transform: ${({ isCollapsed }: any) =>
    isCollapsed ? "rotate(0deg)" : "rotate(180deg)"}; // Rotate when collapsed
`;


type AddressForm = AddAddressRequest & {
  radio_select: string;
  phonePrefix: any;
};

const StepOne = ({ billingRef }) => {
  // variables
  const { t } = useTranslation();
  const tabContext = useContext(TabContext);
  const { data } = useGetAddresses();
  const { setBillingDetails } = usePayment();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isCollapsedAdd, setIsCollapsedAdd] = useState(data?.length > 0 ? true : false);
  const { accountSetting } = useAuth();

  const mainOptions = appCountries(accountSetting?.language)?.map((country: ICountry) => ({
    value: country.isoCode,
    label: country.name,
  }));

  const { setActiveTab } = tabContext;
  const contentRef = useRef<any>(null);
  const { formData, setFormData } = useFormContext();

  const {
    handleSubmit,
    watch,
    trigger,
    getValues,
    setValue,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<AddressForm>({
    defaultValues: {
      isCompany: null,
      ...formData,
    },
  });

  useEffect(() => {
    if (formData) {
      reset(formData);
    }
  }, [formData, reset]);

  useEffect(() => {
    if (data) {
      const isExistingDefault = data.find((item) => item.isDefault);

      if (isExistingDefault) {
        setValue("radio_select", isExistingDefault.billingId);
      }
    }
  }, [data]);

  // toggle collapse function
  const watchCountry = watch("country");
  const watchCity = watch("city");
  const watchIsCompany = watch("isCompany");
  const watchRadioSelect = watch("radio_select");
  const watchIsSaved = watch("isSaved");

  const city = City.getCitiesOfCountry(getValues("country"));

  const mainCity = city?.map((citys: any) => ({
    value: citys.name,
    label: citys.name,
  }));

  const setBillingId = () => {
    localStorage.setItem("billingId", getValues("radio_select"));
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleCollapseAdd = () => {
    setIsCollapsedAdd(!isCollapsedAdd);
  };

  const onSubmit = async (values: AddressForm, e: React.FormEvent) => {
    e.preventDefault();

    const formDataObj: AddAddressRequest = {
      name: values?.name,
      surname: values?.surname,
      country: values?.country,
      city: values?.city,
      address: values?.address,
      addressExtra: values?.addressExtra,
      zipCode: values?.zipCode,
      isCompany: values?.isCompany,
      isSaved: values.isSaved,
      isDefault: values.isDefault,
      ...(values?.isCompany === true
        ? { companyName: values?.companyName, taxId: values?.taxId }
        : { personId: values?.nif }),
    };

    setFormData(values);
    setBillingDetails(formDataObj);
    setActiveTab(1);
  };

  const processExistingAddress = () => {
    const filteredAddress = data?.find(
      (address: any) => address.billingId === watchRadioSelect
    );

    setBillingDetails(filteredAddress);
    setActiveTab(1);
  };

  useEffect(() => {
    setBillingId();
    if (watchRadioSelect) {
      setValue("isCompany", false);
    }
  }, [watchRadioSelect]);

  useEffect(() => {
    if (watchIsCompany !== null) setValue("radio_select", null);
  }, [watchIsCompany]);

  const isIndividual =
    (watchIsCompany === false && !data.find((item) => item.isDefault)) ||
    watchIsCompany === false;

  return (
    <ResponsiveDiv className="mb-5">
      {data?.length > 0 && (
        <>
          <div className="mb-3">
            <Heading
              fontSize="14px"
              title="profile.billingDetailsSaved"
              fontWeight="700"
              color={theme.colors.white.dark}
            />
          </div>
        </>
      )}
      <div>
        {data?.slice(0, 2)?.map((elem: Address) => {
          return (
            <AddressItem
              key={elem.billingId}
              item={elem}
              onClick={() => setValue("radio_select", elem?.billingId)}
              isChecked={watch("radio_select") === elem?.billingId}
              registerProps={control.register("radio_select") as any}
              hideDelete
            />
          );
        })}

        {data?.slice(2).length > 0 && (
          <>
            <ToggleContainer onClick={toggleCollapse}>
              <Heading
                fontSize="14px"
                title={t("actions.seeMore")}
                fontWeight="700"
                color={theme.colors.white.normal}
                style={{ cursor: "pointer", textDecoration: "underline" }} // Makes it look clickable
              />
              <RotatingIcon
                icon="fe:arrow-down"
                width="18"
                height="18"
                isCollapsed={isCollapsed}
              />
            </ToggleContainer>

            <CollapsibleSection
              ref={contentRef}
              isCollapsed={isCollapsed}
              maxHeight={isCollapsed ? "0px" : `100%`}
            >
              {data?.slice(2)?.map((elem: Address) => {
                return (
                  <AddressItem
                    key={elem.billingId}
                    item={elem}
                    onClick={() => setValue("radio_select", elem?.billingId)}
                    isChecked={watch("radio_select") === elem?.billingId}
                    registerProps={control.register("radio_select") as any}
                    hideDelete
                  />
                );
              })}
            </CollapsibleSection>
          </>
        )}

        <form
          onSubmit={
            watchRadioSelect === null
              ? handleSubmit(onSubmit)
              : processExistingAddress
          }
          ref={billingRef}
        >
          {data?.length > 0 && (
            <>
              <div className="d-block d-md-none mb-3">
                <Button
                  onClick={toggleCollapseAdd}
                  title="actions.addAnother"
                  isLoading={isSubmitting}
                  type="button"
                  outline
                  startIcon={<Icon icon="mdi:plus-box-outline" width={16} />}
                  size="lg"
                  style={{
                    width: "100%",
                    fontWeight: "700",
                    fontSize: "14px",
                    borderRadius: "8px",
                    margin: 0,
                  }}
                />
              </div>
              <div className=" d-none d-md-block mb-3">
                <Heading
                  fontSize="14px"
                  onClick={toggleCollapseAdd}
                  title={t("actions.addAnother")}
                  fontWeight="700"
                  color={theme.colors.white.dark}
                  style={{cursor:'pointer'}}
                />
              </div>{" "}
            </>
          )}

          <CollapsibleSection
            ref={contentRef}
            isCollapsed={isCollapsedAdd}
            maxHeight={isCollapsedAdd ? "0px" : `100%`}
          >
            <Row className="g-4">
              <Col md={6} sm={6} xs={6}>
                <div
                  className="d-flex align-items-center"
                  onClick={() => setValue("isCompany", false)}
                >
                  <RadioWrapper>
                    <RadioButton
                      name="isCompany"
                      value={false}
                      checked={isIndividual}
                      {...control.register("isCompany", {
                        required: false,
                      })}
                    />
                  </RadioWrapper>

                  <div className="mx-3" style={{ cursor: "pointer" }}>
                    <Heading
                      fontSize="14px"
                      title={t("profile.individual")}
                      fontWeight="400"
                      color={theme.colors.white.normal}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6} sm={6} xs={6}>
                <div
                  className="d-flex align-items-center"
                  onClick={() => setValue("isCompany", true)}
                >
                  <RadioWrapper>
                    <RadioButton
                      name="isCompany"
                      value={true}
                      checked={watch("isCompany") === true}
                      {...control.register("isCompany", {
                        required: false,
                      })}
                    />
                  </RadioWrapper>

                  <div className="mx-3 " style={{ cursor: "pointer" }}>
                    <Heading
                      fontSize="14px"
                      title={t("profile.isCompany")}
                      fontWeight="400"
                      color={theme.colors.white.normal}
                    />
                  </div>
                </div>
              </Col>

              {!watchIsCompany && (
                <Col md={!watchIsCompany ? 6 : 12} sm={12} xs={12}>
                  <TextField
                    floatingLabel
                    control={control}
                    name="name"
                    bgColor={theme.colors.grey.dark}
                    maxLength={35}
                    size="sm"
                    onBlur={(e) => {
                      trigger("name");
                    }}
                    placeholder={t("profile.name")}
                    errors={errors.name}
                    rules={{
                      required: t("errors.required"),
                      pattern: {
                        value: regexPatterns?.regOnlyChar,
                        message: t("errors.namePattern"),
                      },
                    }}
                  />
                </Col>
              )}

              {!watchIsCompany ? (
                <Col md={6} sm={12} xs={12}>
                  <TextField
                    floatingLabel
                    control={control}
                    name="surname"
                    onBlur={(e) => {
                      trigger("surname");
                    }}
                    bgColor={theme.colors.grey.dark}
                    maxLength={35}
                    size="sm"
                    placeholder={t("profile.surname")}
                    errors={errors.surname}
                    rules={{
                      required: t("errors.required"),
                      pattern: {
                        value: regexPatterns?.regOnlyChar,
                        message: t("errors.namePattern"),
                      },
                    }}
                  />
                </Col>
              ) : null}
              {watchIsCompany === true && (
                <Col md={12} sm={12} xs={12}>
                  <TextField
                    floatingLabel
                    control={control}
                    name="companyName"
                    bgColor={theme.colors.grey.dark}
                    maxLength={35}
                    size="sm"
                    onBlur={(e) => {
                      trigger("companyName");
                    }}
                    placeholder={t("profile.companyName")}
                    errors={errors.companyName}
                    rules={{
                      required: t("errors.required"),
                      pattern: {
                        value: regexPatterns?.regTextWithNumbers,
                        message: t("errors.namePattern"),
                      },
                    }}
                  />
                </Col>
              )}
              <Col md={6} sm={6} xs={6}>
                <SearchSelectBox
                  bgColor={theme.colors.grey.dark}
                  size="sm"
                  selectValue="isoCode"
                  selectLabel="name"
                  options={mainOptions}
                  placeholder={t("profile.country")}
                  control={control}
                  name="country"
                  setValue={setValue}
                  errors={errors.country}
                  value={watchCountry}
                  rules={{ required: t("errors.required") }}
                  defaultValue={formData?.country}
                />
              </Col>
              <Col md={6} sm={6} xs={6}>
                <SearchSelectBox
                  bgColor={theme.colors.grey.dark}
                  size="sm"
                  options={mainCity}
                  selectValue="stateCode"
                  selectLabel="name"
                  placeholder={t("profile.city")}
                  control={control}
                  name="city"
                  setValue={setValue}
                  errors={errors.city}
                  value={watchCity}
                  rules={{ required: t("errors.required") }}
                  defaultValue={formData?.city}
                />
              </Col>

              <Col md={12} sm={12} xs={12}>
                <TextField
                  floatingLabel
                  control={control}
                  name="address"
                  onBlur={(e) => {
                    trigger("address");
                  }}
                  bgColor={theme.colors.grey.dark}
                  maxLength={55}
                  size="sm"
                  placeholder={t("profile.address")}
                  errors={errors.address}
                  rules={{ required: t("errors.required") }}
                />
              </Col>

              <Col md={12} sm={12} xs={12}>
                <TextField
                 floatingLabel
                  control={control}
                  name="addressExtra"
                  bgColor={theme.colors.grey.dark}
                  maxLength={55}
                  size="sm"
                  placeholder={t("profile.additionalAddress")}
                  rules={{ required: false }}
                />
              </Col>

              <Col md={6} sm={6} xs={4}>
                <TextField
                  floatingLabel
                  control={control}
                  name="zipCode"
                  bgColor={theme.colors.grey.dark}
                  maxLength={10}
                  placeholder={t("profile.zipCode")}
                  size="sm"
                  onBlur={(e) => {
                    trigger("zipCode");
                  }}
                  errors={errors.zipCode}
                  rules={{
                    required: t("errors.required"),
                    pattern: {
                      value: regexPatterns?.zipCodeRegex,
                      message: t("errors.zipCode"),
                    },
                  }}
                />
              </Col>

              <Col md={6} sm={6} xs={8}>
                {watchIsCompany ? (
                  <TextField
                    floatingLabel
                    control={control}
                    name={"taxId"}
                    bgColor={theme.colors.grey.dark}
                    maxLength={15}
                    size="sm"
                    placeholder={"Tax ID*"}
                    onBlur={(e) => {
                      trigger("taxId");
                    }}
                    errors={errors.taxId}
                    rules={{ required: t("errors.required") }}
                  />
                ) : (
                  <TextField
                    floatingLabel
                    control={control}
                    name={"nif"}
                    onBlur={(e) => {
                      trigger("nif");
                    }}
                    bgColor={theme.colors.grey.dark}
                    maxLength={15}
                    size="sm"
                    placeholder={"TIN*"}
                    errors={errors.nif}
                    rules={{ required: t("errors.required") }}
                  />
                )}
              </Col>

              <Col md={12} className="d-flex flex-column flex-md-row gap-3 gap-md-4">
                <CheckboxField
                  control={control}
                  name="isSaved"
                  label={t("payments.saveBillingDetails")}
                  watch={watchIsSaved}
                  style={{width:'auto'}}
                />
                <CheckboxField
                  control={control}
                  name="isDefault"
                  label={t("payments.defaultBillingDetails")}
                  disabled={!watchIsSaved}
                  style={{width:'auto'}}
                />
              </Col>
            </Row>
          </CollapsibleSection>

          <PaymentFooter isSubmitting={isSubmitting} />
        </form>
      </div>
    </ResponsiveDiv>
  );
};

export default StepOne;
