import React, { useEffect } from "react";

import CopyBox from "./components/copyBox";
import { usePayment } from "@context/PaymentContext";
import { paymentMethods } from "@utils/payments";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Heading from "@components/font/heading";
import { theme } from "@styles/themes";

const BankTransferDetail = () => {
  const { isPaymentSuccess, paymentMethod, amount, bankTransferResponse, currency, convertToPaymentCurrency} = usePayment();
  const { t } = useTranslation();
  
  useEffect(() => {
    if (isPaymentSuccess && paymentMethods.bankTransfer === paymentMethod) {
      toast.success(t("success.bankTransfer"));
    }
  }, [isPaymentSuccess]);

  const dataBankDetail = {
    'eur': {
      concept: bankTransferResponse?.data?.referenceNumber,
      titular: "DRACAL SL",
      bic: "BINAADADXXX",
      iban: "AD2400070089000175917012",
      address: `Morabanc , CTRA DE LA RABASSA SUPERLAUREDIA 3 1 2 601 SANT JULIA DE LORIA AD`,
      amount: convertToPaymentCurrency(amount)
    },
    'usd': {
      concept: bankTransferResponse?.data?.referenceNumber,
      titular: "DRACAL SL",
      bic: "BINAADADXXX",
      iban: "AD2400070089000175917012",
      address: `Morabanc , CTRA DE LA RABASSA SUPERLAUREDIA 3 1 2 601 SANT JULIA DE LORIA AD`,
      amount: convertToPaymentCurrency(amount)
    }
  }

  const bankAccount = dataBankDetail[currency];

  return (
    <div>
      <CopyBox title="payments.accountTitular" code={bankAccount.titular} />
      <CopyBox title="payments.accountNumber" code={bankAccount.accountNumber} />
      <CopyBox title="payments.routeNumber" code={bankAccount.routeNumber} />
      <CopyBox title="payments.iban" code={bankAccount.iban} />
      <CopyBox title="payments.bic" code={bankAccount.bic} />
      <CopyBox
        title="payments.namebankaddress"
        code={bankAccount.address}
      />
      <CopyBox title="payments.AmountToSend" code={bankAccount.amount} />
      <CopyBox title="payments.useConceptBelow" code={bankAccount.concept} />

      <div style={{ padding: "0 16px", marginTop: "12px" }}>
        <Heading
          fontSize="14px"
          title="payments.conceptDisclaimer"
          fontWeight="700"
          color={theme.colors.white.normal}
        />
      </div>
    </div>
  );
};

export default BankTransferDetail;
