import { useMutation } from "react-query";
import { AxiosError } from "axios";

import request from "@utils/request";
import { ServerResponse } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";

export interface ResendVerificationLinkRequest {
  email: string;
}

export interface ResendVerificationLinkResponse {
  message: string;
}

export type ResendVerificationLinkServer = ServerResponse<ResendVerificationLinkResponse>;

export const useResendVerificationLink = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: ResendVerificationLinkServer) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  const { triggerError } = useErrorHandler();

  return useMutation<
    ResendVerificationLinkServer,
    ServerResponse<unknown> | undefined,
    ResendVerificationLinkRequest
  >(["resendVerificationLink"], async (data: ResendVerificationLinkRequest) => {
    try {
      const response = await request<ResendVerificationLinkServer>({
        method: "post",
        url: "signup/resendverification",
        data,
      });
      
      if (onSuccess) {
        onSuccess(response.data);
      }

      return response.data;
    } catch (error) {
      if (onError) {
        onError(error as AxiosError<ServerResponse<unknown>>);
      }
      triggerError({ error });
      throw error;
    }
  });
};
