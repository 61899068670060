import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import { regexPatterns } from "@utils/regex";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const StepTwo = ({ control, setValue, errors,trigger }: any) => {
  const { t } = useTranslation();

  return (
    <div>
      <Row className="g-4">
        <Col md={6} sm={12} xs={12}>
          <TextField
            floatingLabel
            control={control}
            name="nickname"
            onBlur={(e) => {
              trigger("nickname");
            }}
            bgColor={theme.colors.grey.dark}
            maxLength={30}
            size="sm"
            placeholder={t("signUp.username")}
            errors={errors.nickname}
            rules={{
              required: t("errors.required"),
              pattern: {
                value: regexPatterns?.regUsername,
                message: t("errors.usernamePattern"),
              },
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StepTwo;
