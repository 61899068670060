import React from "react";
import { Col, Row } from "react-bootstrap";
import CountryCodePicker from "@components/form/phoneInput";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import { countryCodes } from "@utils/countries";
import { regexPatterns } from "@utils/regex";
import { useCheckNickname } from "@api/useCheckNickname";
import { useCheckEmail } from "@api/useCheckEmail";
import { useTranslation } from "react-i18next";

const StepOne = ({ control, trigger, watch, errors, setValue, getValues, clearErrors, setError} : any)=> {
   const { t } = useTranslation();
   const nicknameValue = watch("nickname");
   const emailValue = watch("email");
   
   const checkNickname = useCheckNickname({
    onError: (err) => {
      if (err.response?.status === 409) {
        setError("nickname", {
          type: "manual",
          message: t("signUp.usernameAlreadyExist"),
        });
      }
    },
  });

  const checkEmail = useCheckEmail({
    onError: (err) => {
      if (err.response?.status === 409) {
        setError("email", {
          type: "manual",
          message: t("signUp.emailAlreadyRegistered"),
        });
      }
    },
  });

  const handleOnBlurNickName = async () => {
    const isValid = await trigger("nickname");
    if (nicknameValue && isValid) {
      clearErrors("nickname");
    }
    checkNickname.mutate({ nickname: nicknameValue });
  };

  const handleOnBlurEmail = async () => {
    const isValid = await trigger("email");
    if (nicknameValue && isValid) {
      clearErrors("email");
    }
    checkEmail.mutate({ email: emailValue });
  };

    return (
        <Row className="g-4" style={{ maxWidth: "500px" }}>
        <Col md={6} sm={12} xs={12}>
          <TextField
           floatingLabel
            control={control}
            name="firstName"
            bgColor={theme.colors.grey.dark}
            maxLength={35}
            size="sm"
            onBlur={(e) => {
              trigger("firstName");
            }}
            placeholder={t("signUp.name")}
            errors={errors.firstName}
            rules={{
              required: t("errors.required"),
              pattern: {
                value: regexPatterns?.regOnlyChar,
                message: t("errors.namePattern"),
              },
            }}
          />
        </Col>
        <Col md={6} sm={12} xs={12}>
          <TextField
           floatingLabel
            control={control}
            name="lastName"
            onBlur={(e) => {
              trigger("lastName");
            }}
            bgColor={theme.colors.grey.dark}
            maxLength={35}
            size="sm"
            placeholder={t("signUp.lastName")}
            errors={errors.lastName}
            rules={{
              required: t("errors.required"),
              pattern: {
                value: regexPatterns?.regOnlyChar,
                message: t("errors.namePattern"),
              },
            }}
          />
        </Col>
        <Col md={12} sm={12} xs={12}>
          <TextField
            floatingLabel
            control={control}
            name="email"
            bgColor={theme.colors.grey.dark}
            maxLength={50}
            size="sm"
            placeholder={t("signUp.email")}
            errors={errors.email}
            onBlur={handleOnBlurEmail}
            rules={{
              required: t("errors.required"),
              pattern: {
                value: regexPatterns?.regEmail,
                message: t("errors.emailPattern"),
              },
            }}
          />
        </Col>

        <Col md={3} sm={3} xs={6} className="d-flex align-items-start">
          <CountryCodePicker
            bgColor={theme.colors.grey.dark}
            size="sm"
            options={countryCodes}
            placeholder={t("signUp.prefix")}
            control={control}
            name="phonePrefix"
            setValue={setValue}
            errors={errors.phonePrefix}
            rules={{ required: false }}
          />
        </Col>

        <Col md={9} sm={9} xs={6} className="d-flex align-items-end">
          <TextField
            floatingLabel
            control={control}
            name="phoneNumber"
            onBlur={(e) => {
              trigger("phoneNumber");
            }}
            bgColor={theme.colors.grey.dark}
            maxLength={15}
            size="sm"
            placeholder={t("signUp.phoneNumber")}
            errors={errors.phoneNumber}
            rules={{
              required: false,
              pattern: {
                value: regexPatterns?.regOnlyNum,
                message: t("errors.phoneNumberPattern"),
              },
            }}
          />
        </Col>
        <Col md={12} sm={12} xs={12}>
          <TextField
            floatingLabel
            control={control}
            name="nickname"
            bgColor={theme.colors.grey.dark}
            maxLength={30}
            size="sm"
            placeholder={t("signUp.username")}
            errors={errors.nickname}
            onBlur={handleOnBlurNickName}
            rules={{
              required: t("errors.required"),
              pattern: {
                value: regexPatterns.regUsername,
                message: t("errors.usernamePattern"),
              },
            }}
          />
        </Col>
        <Col md={12} sm={12} xs={12}>
          <TextField
            floatingLabel
            control={control}
            name="telegramUser"
            defaultValue={""}
            bgColor={theme.colors.grey.dark}
            maxLength={25}
            size="sm"
            placeholder="@Telegram"
            onBlur={() => {
              const value = getValues("telegramUser") || "";
              if (value && !value.startsWith("@")) {
                setValue("telegramUser", `@${value}`);
              }
              trigger("telegramUser");
            }}
            onChange={(e) => {
              const value = e.target.value;
              if (!value.startsWith("@") && value.length > 0) {
                setValue("telegramUser", `@${value}`);
              } else {
                setValue("telegramUser", value);
              }
            }}
            errors={errors.telegramUser}
            rules={{
              required: false,
              pattern: {
                value: regexPatterns?.regTelegram,
                message: t("errors.telegramPattern"),
              },
            }}
          />
        </Col>
        <Col md={12} sm={12} xs={12}>
          <TextField
            floatingLabel
            control={control}
            name="password"
            onBlur={(e) => {
              trigger("password");
            }}
            bgColor={theme.colors.grey.dark}
            maxLength={25}
            size="sm"
            type="password"
            placeholder={t("signUp.password")}
            errors={errors.password}
            rules={{
              required: t("errors.required"),
              minLength: {
                value: 6,
                message: t("errors.passwordPattern"),
              },
              pattern: {
                value: regexPatterns?.regPassword,
                message: t("errors.passwordPattern"),
              },
            }}
          />
        </Col>
        <Col md={12} sm={12} xs={12}>
          <TextField
            floatingLabel
            control={control}
            name="confirmPassword"
            onBlur={(e) => {
              trigger("confirmPassword");
            }}
            bgColor={theme.colors.grey.dark}
            maxLength={25}
            size="sm"
            type="password"
            placeholder={t("signUp.confirmPassword")}
            errors={errors.confirmPassword}
            rules={{
              required: t("errors.required"),
              validate: (value: any) =>
                value === watch("password") || t("errors.passwordNotMatch"),
            }}
          />
        </Col>
      </Row>
    )
}

export default StepOne;
