import React, { ReactNode } from "react";
import {
  Controller,
  Control,
  FieldValues,
  FieldError,
  PathValue,
  Path,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { theme } from "@styles/themes";
import { Icon } from "@iconify/react";

const InputContainer = styled.div`
  position: relative;
  margin: 0 auto;
`;

const LabelText = styled.label<{ isActive: boolean }>`
  width: auto;
  font-size: 10px;
  font-weight: 400;
  color: ${theme.colors.white.normal};
  position: absolute;
  top: ${(props) => (props.isActive ? "0px" : "50%")};
  left: 10px;
  transform: translateY(-50%);
  transition: all 0.2s ease;
  pointer-events: none;
  background-color: ${theme.colors.grey.dark};
  padding: 0 4px;
  border-radius: 4px;
  z-index: 1;
`;

const StyledInput = styled.input<{
  error?: boolean;
  size: "sm" | "md" | "lg";
  bgColor?: string;
  endIcon?: boolean;
}>`
  ${(props) =>
    props.size === "lg"
      ? `
        padding: 12px 10px;
      `
      : props.size === "sm"
      ? `
        padding: 6px 10px;
      `
      : `
        padding: 10px 10px;
      `}
  padding-right: ${(props) => (props.endIcon ? "40px" : "10px")};
  border: 1px solid ${theme.colors.grey.dark};
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${(props) => props.bgColor || theme.colors.grey.dark};
  color: ${theme.colors.white.normal};

  &::placeholder {
    color: ${theme.colors.white.dark};
  }

  &:focus {
    border-color: ${theme.colors.white.normalActive};
    outline: none;
  }

  ${(props) =>
    props.error &&
    `
    &::placeholder {
      color: ${theme.colors.red.normal};
    }
    border-color: ${theme.colors.red.normal};
  `}

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const StyledTextarea = styled.textarea<{
  error?: boolean;
  size: "sm" | "md" | "lg";
  bgColor?: string;
}>`
  ${(props) =>
    props.size === "lg"
      ? `
        padding: 12px 10px;
      `
      : props.size === "sm"
      ? `
        padding: 6px 10px;
      `
      : `
        padding: 10px 10px;
      `}
  border: 1px solid ${theme.colors.grey.dark};
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  max-height: 100px;
  min-height: 100px;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  resize: none; 
  background-color: ${(props) => props.bgColor || theme.colors.grey.dark};
  color: ${theme.colors.white.normal};

  &::placeholder {
    color: ${theme.colors.white.dark};
  }

  &:focus {
    border-color: ${theme.colors.white.normalActive};
    outline: none;
  }

  ${(props) =>
    props.error &&
    `
    &::placeholder {
      color: ${theme.colors.red.normal};
    }
    border-color: ${theme.colors.red.normal};
  `}
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
`;
const EyeIconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 100;
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  color: ${theme.colors.red.normal};
  font-size: 10px;
  margin-top: 2px;
  margin-left: 8px;
`;

interface TextFieldProps<T extends FieldValues>
  extends Pick<React.InputHTMLAttributes<HTMLInputElement>, "onBlur"> {
  placeholder?: string;
  control: Control<T>;
  endIcon?: ReactNode;
  size?: "sm" | "md" | "lg";
  bgColor?: string;
  labelText?: string;
  name: Path<T>;
  assistiveText?: string;
  rules?: any;
  disabled?: boolean;
  defaultValue?: PathValue<T, Path<T>>;
  errors?: FieldError;
  type?: string;
  style?: React.CSSProperties;
  className?: string;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  value?: PathValue<T, Path<T>>;
  multiline?: boolean;
  lines?: number;
  floatingLabel?: boolean;
  maxLength?: number;
}

export const TextField = <T extends FieldValues>({
  placeholder,
  control,
  endIcon,
  size = "md",
  bgColor,
  labelText,
  name,
  assistiveText,
  rules,
  disabled,
  defaultValue,
  errors,
  type = "text",
  style,
  className,
  onChange,
  value,
  multiline = false,
  lines = 3,
  onBlur,
  floatingLabel = false,
  maxLength
}: TextFieldProps<T>) => {
  const { t } = useTranslation();
  const [passwordEye, setpasswordEye] = React.useState<string>(type);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [isActive, setIsActive] = React.useState<boolean>(!!value);

  const handleFocus = () => setIsActive(true);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsActive(!!e.target.value);
    onBlur && onBlur(e as React.FocusEvent<HTMLInputElement>);
  };
  

  const handlePasswordEye = (type: string) => {
    if (type === "text") {
      setpasswordEye(type);
      setShowPassword(true);
    } else if (type === "password") {
      setpasswordEye(type);
      setShowPassword(false);
    }
  };

  return (
    <div style={{ width: "100%", ...style }} className={className}>
      <InputContainer>
          <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({
            field: { onChange: handleChange, value: fieldValue },
          }) => {
            const updatedLabel =
            fieldValue && placeholder
              ? placeholder.replace(/\*$/, "")
              : placeholder;
            return (
            <>
                {floatingLabel && (isActive || !!fieldValue) && (
                  <LabelText isActive={isActive || !!fieldValue}>
                    {t(updatedLabel || "Input")}
                  </LabelText>
                )}
              {multiline ? (
                <StyledTextarea
                  onFocus={handleFocus}
                  disabled={disabled}
                  placeholder={!isActive && (t(placeholder) || "Type something...")}
                  onChange={(e) => {
                    handleChange(e);
                    onChange && onChange(e);
                  }}
                  error={!!errors}
                  size={size}
                  value={fieldValue || value}
                  bgColor={bgColor}
                  onBlur={handleBlur}
                  rows={lines}
                  maxLength={maxLength}
                />
              ) : (
                <StyledInput
                  onFocus={handleFocus}
                  disabled={disabled}
                  type={showPassword? passwordEye : type}
                  defaultValue={defaultValue}
                  placeholder={!isActive && (t(placeholder) || "Type something...")}
                  onChange={(e) => {
                    handleChange(e);
                    onChange && onChange(e);
                  }}
                  error={!!errors}
                  size={size}
                  value={fieldValue || value}
                  bgColor={disabled ? theme.colors.grey.normal : bgColor}
                  endIcon={!!endIcon}
                  onBlur={handleBlur}
                  maxLength={maxLength}
                />
              )}
              {type === "password" && (
                <EyeIconContainer>
                  {showPassword ? (
                    <Icon
                      color={theme.colors.white.dark}
                      width={20}
                      icon="mi:eye"
                      onClick={() => handlePasswordEye("password")}
                    />
                  ) : (
                    <Icon
                      color={theme.colors.white.dark}
                      width={20}
                      icon="mi:eye-off"
                      onClick={() => handlePasswordEye("text")}
                    />
                  )}
                </EyeIconContainer>
              )}
              {endIcon && <IconContainer>{endIcon}</IconContainer>}
            </>
          )}}
        />
        {assistiveText && <small>{t(assistiveText)}</small>}
      </InputContainer>
      {errors && (
        <ErrorMessage>{t(errors.message) || t("errors.required")}</ErrorMessage>
      )}
    </div>
  );
};
